<template>
	<li class="side-nav-item">
		<span
			v-if="!path && label"
			class="category"
			:class="{ collapsed, active }"
			@click="toggleCollapsed"
		>
			{{ label }}
			<transition name="slide-in-out">
				<ul v-show="!collapsed">
					<SideNavItem
						v-for="item in items"
						:key="item.label"
						:path="item.path"
						:items="item.items"
						:label="item.label"
					/>
				</ul>
			</transition>
		</span>
		<span v-if="path">
			<router-link :to="path" tag="a" :class="active">
				{{ label }}
				<span v-show="counter" class="counter-badge">
					{{ counter }}
				</span>
			</router-link>
		</span>
	</li>
</template>

<script>
import axiosApiClient from '@/api/axiosApiClient'
import moment from 'moment'

export default {
	name: 'SideNavItem',
	props: {
		label: {
			type: String,
			default: null
		},
		path: {
			type: String,
			default: null
		},
		items: {
			type: Array,
			default() {
				return []
			}
		}
	},
	data() {
		const { path } = this.$route
		const active =
			path.startsWith(this.path) ||
			this.items.some(i => path.startsWith(i.path))
		return {
			collapsed: !active,
			counter: null,
			active
		}
	},
	created() {
		const getUnassignedDeliveryCounter = async () => {
			if (this.label !== 'Deliveries') {
				return
			}
			const before = moment().add(-7, 'd').toISOString()
			const later = moment().add(7, 'd').toISOString()
			const response = await axiosApiClient({
				url: `delivery/count?courierId&min_deliveryBy=${before}&max_deliveryBy=${later}`
			})
			this.counter = response.data || null // to filter the 0
		}
		getUnassignedDeliveryCounter()
	},
	methods: {
		toggleCollapsed: function () {
			this.collapsed = !this.collapsed
		}
	}
}
</script>

<style lang="scss">
.counter-badge {
	font-size: 1.2rem;
	padding: 1px 5px;
	background-color: #8b0000;
	color: #f5f5f5;
	border-radius: 50%;
}

.side-nav-item .category.collapsed:before {
	content: '\25B8';
}

.side-nav-item .category:before {
	content: '\25BE';
}

.side-nav-item .category ul {
	padding-top: 10px;
}

.slide-in-out-enter-active,
.slide-in-out-leave-active {
	transition: all 0.1s;
}

.slide-in-out-enter,
.slide-in-out-leave-to {
	opacity: 0;
}

#side-nav .side-nav-item a,
.side-nav-item .category {
	padding: 6px 8px 6px 0;
	text-decoration: none;
	color: #818181;
	font-weight: normal;
	display: block;
	cursor: pointer;
	font-size: 14px;
}

#side-nav .side-nav-item a:hover,
.side-nav-item .category:hover {
	color: #f16722;
}

.side-nav-item {
	list-style: none;
	font-weight: normal;
	padding-left: 0;
}

#side-nav .side-nav-item a.router-link-active,
.category.active {
	font-weight: bold;
	color: #f16722;
}
</style>
