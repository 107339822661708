<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'

const config = {
	options: {
		resource: 'alert-subscriptions',
		schemaModifier: async data => {
			//	remove this line if need to support bulk
			data = data.items
			data.autocompleteFields = [
				{
					name: 'alertTypeId',
					resource: 'alert-types',
					query: 'id',
					display: 'id'
				}
			]
			return data
		}
	}
}

export default {
	name: 'AlertSubscriptionsNew',
	components: { NewPageGen },
	data: () => config
}
</script>
