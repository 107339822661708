<script>
import _ from 'lodash'
import SideNavItem from './SideNavItem.vue'
import { rolePermissionCheck } from '../permission/rolePermission'

const { VUE_APP_ENV, VUE_APP_LLP_15565_SHIFT_DASHBOARD } = process.env

export default {
	name: 'SideNav',
	components: { SideNavItem },
	data() {
		const deepCheckDisplayRole = data => {
			if (_.isArray(data)) {
				const result = []
				data.forEach(d => {
					const subData = deepCheckDisplayRole(d)
					if (subData) {
						result.push(subData)
					}
				})
				return result
			}
			if (_.isObject(data)) {
				if (data.env && data.env.length !== 0) {
					if (!data.env.includes(VUE_APP_ENV)) {
						return null
					}

					delete data.env
				}
				if (data.role === true) {
					const dashesCase = data.label
						.replace(' ', '-')
						.toLowerCase()
					if (!rolePermissionCheck(dashesCase, '')) {
						return null
					}

					delete data.role
				}
				if (data.items && data.items.length !== 0) {
					data.items = deepCheckDisplayRole(data.items)
					return data
				}
			}
			return data
		}

		const menu = [
			{ label: 'Dashboard', path: '/dashboard' },
			{ label: 'Deliveries', path: '/deliveries' },
			{ label: 'Orders', path: '/orders' },
			{ label: 'Users', path: '/users' },
			// {
			// 	label: 'Order Matching Statistics',
			// 	path: '/order-matching-statistics'
			// },
			{ label: 'Alerts', path: '/alerts' },
			...(VUE_APP_LLP_15565_SHIFT_DASHBOARD === 'true'
				? [
						{
							label: 'Shift',
							path: '/shift-dashboard'
						}
				  ]
				: []),
			{
				label: 'Courier Management',
				items: [
					{ label: 'Couriers', path: '/couriers' },
					{ label: 'Report Generation', path: '/report-generation' }
				]
			},
			{
				label: 'Config',
				items: [
					{ label: 'Alert Types', path: '/alert-types' },
					{
						label: 'Alert Subscriptions',
						path: '/alert-subscriptions'
					},
					{ label: 'Clients', path: '/clients' },
					{
						label: 'Courier Menu',
						path: '/courier-menu',
						role: true
					},
					{ label: 'Form Fields', path: '/form-fields' },
					{ label: 'Forms', path: '/forms' },
					{ label: 'Locales', path: '/locales' },
					{ label: 'Locations', path: '/locations' },
					// { label: 'Matching Rules', path: '/matching-rules' },
					{
						label: 'Order Spreadsheet Formats',
						path: '/order-spreadsheet-formats'
					},
					{
						label: 'Orders Spreadsheet Submissions',
						path: '/orders-spreadsheet-submissions'
					},
					{ label: 'Pickup Points', path: '/pickup-points' },
					{ label: 'Routes', path: '/routes' },
					{
						label: 'Summary Items Quick Actions',
						path: '/summary-item-actions'
					},
					{
						label: 'Summary Status Quick Actions',
						path: '/summary-status-actions'
					},
					{ label: 'User Groups', path: '/user-groups' },
					{ label: 'User Tag Keys', path: '/user-tag-keys' },
					{ label: 'Order Tag Keys', path: '/order-tag-keys' }
				]
			},
			{
				label: 'Other',
				items: [
					{ label: 'API Tokens', path: '/api-tokens' },
					{ label: 'Audit Logs', path: '/audit-logs' },
					{ label: 'Crossdocks', path: '/crossdocks' },
					{ label: 'Form Submissions', path: '/form-submissions' },
					{ label: 'Webhooks', path: '/webhooks' },
					{ label: 'SMS', path: '/sms', role: true }
				]
			}
		]

		return { menu: deepCheckDisplayRole(menu) }
	}
}
</script>

<template>
	<ul id="side-nav">
		<SideNavItem
			v-for="item in menu"
			:key="item.label"
			:path="item.path"
			:items="item.items"
			:label="item.label"
		/>
	</ul>
</template>

<style>
#side-nav {
	height: 100%;
	width: 170px;
	z-index: 1;
	margin-top: 2%;
	text-align: left;
	list-style: none;
	padding-left: 0;
}
</style>
