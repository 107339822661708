<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'alert-subscriptions',
		display: ['id', 'userGroup', 'alertTypeId', 'createdAt', 'updatedAt'],
		canEdit: false
	}
}

export default {
	name: 'AlertSubscriptionsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
